import React, { useLayoutEffect } from 'react'
import { AccruentImg } from './commonComponents';
import "src/scss/components/_aboutUsContent.scss";
const getImageObject = require("src/../helpers/getImageObject")

/**
 * @param {Array of Objects} entity - { fieldAboutUsContentSection: Array of Objects, ...}
 */

const AboutUsContent = ({ entity }) => {
    const fieldAboutUsContentSection = entity?.relationships?.fieldAboutUsContentSection
    if (!Array.isArray(fieldAboutUsContentSection)) return <></>

    /**
  * @Table
  */
    const checkForTable =  (entity?.fieldAboutUsConsectionFormat === "about_us_content_with_table")
    let table = fieldAboutUsContentSection?.find((entity) =>
        entity?.title?.toLowerCase().includes("table")
    );

    useLayoutEffect(() => {
        if (checkForTable) {
            const wrapper = document.querySelector(".table-wrapper");
            if (wrapper) {
                const table = wrapper.querySelector("table");
                table.classList.add(
                    "table",
                    "is-striped",
                    "is-bordered",
                    "is-fullwidth",
                    "partners-table"
                );
            }
        }
    }, [table]);

    if (!checkForTable) {
        return (
            <div className='about-us-content-container'>
                <section className="section">
                    <div className="columns">
                        <div className="column is-12">
                            <h2 className="title title-3">
                                Accruent Logo
                            </h2>
                            <AccruentImg
                                file={getImageObject(fieldAboutUsContentSection?.[0]?.relationships?.fieldImage, fieldAboutUsContentSection?.[0]?.fieldImage)}
                                className="large-color tw-bg-accruent_slightgray block"
                            />
                            <p className="has-text-centered block">
                                {fieldAboutUsContentSection?.[0]?.title}
                            </p>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="columns is-multiline accruent-logos">
                        {fieldAboutUsContentSection?.slice(1, 5).map((item) => {

                            return (
                                <div className="column is-half" key={item.uuid}>
                                    <div className="block">
                                        <AccruentImg
                                            file={getImageObject(item?.relationships?.fieldImage, item?.fieldImage)}
                                        />
                                    </div>
                                    <p className="block has-text-centered">{item.title}</p>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </div>
        )
    }

    return (
        <div className='about-us-content-container'>
            {fieldAboutUsContentSection?.[0] && (
                <>
                    <h2 className="title title-3 mt-3">
                        {fieldAboutUsContentSection[0]?.title}
                    </h2>
                    {fieldAboutUsContentSection[0]?.body && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: fieldAboutUsContentSection[0]?.body?.value,
                            }}
                            key={fieldAboutUsContentSection[0]?.uuid}
                            className="ccpa-content-body dangerously-html-handle"
                        />
                    )}
                </>
            )}
            {checkForTable && table && (
                <div className="table-wrapper">
                    <div
                        dangerouslySetInnerHTML={{ __html: table?.body?.value }}
                        className="dangerously-html-handle"
                    />
                </div>
            )}
        </div>
    )
}

export default AboutUsContent